import React, {useContext, useEffect, useState} from 'react'
import {LangContext, translations} from "../Language"
import NormalButton from '../shared/NormalButton'

export default ({close, initialOpen=true}) =>{
  const l = useContext(LangContext)
  const m = translations[l].modals.modalOpportunity

  const [open, setOpen] = useState(initialOpen)

  const testEnvironment = process.env.REACT_APP_NODE_ENV === 'test'
  
  const closeIt = () =>{
     close&&close() 
     document.body.style.overflow='visible'
     setOpen(false)
  }

  useEffect(() => setOpen(initialOpen), [initialOpen])

  useEffect(() => {
    let modalBg = document.querySelector(".modalBg")
    const clickEvent=()=>{
        closeIt()
    }
    const keyEsc=(e)=>{
        if (e.key=="Escape") {
            closeIt()
        }
    }
    if (open) {
        document.body.style.overflow='hidden'
        document.documentElement.scrollTop = 0;
        modalBg.addEventListener('click',clickEvent)
        document.addEventListener('keyup', keyEsc)
    }
    else{
      document.body.style.overflow='visible'
    }
    return () => {
      modalBg.removeEventListener("click", clickEvent)
      document.removeEventListener('keyup',keyEsc)
    }
  }, [open])

  return(
    <div className="modalView">
      {open&&<div className="ModalOp">
        <div className="modalBg">
        </div>
        <div className="ModalFather rounded borderWhite">
          <div className="close" onClick={closeIt}>x</div>
          <header className="barraUpContact barraUp">
            <div className="titleModal">{m.titleOpp}</div>
          </header>
          <div className="containerM flex">
            <div className="adentro">
              <p>{m.checkServices}</p>
              <a href="/services">
                <NormalButton id="redirect" listener={()=>{}} color='white' bgColor='var(--darkPurple)' label={m.services} fill={true}/>
              </a>
            </div>
            <div className="adentro">
              <p>{m.labelOpp}</p>
              <a href={`${testEnvironment?"/marketplace":""}/opportunities/new`}>
                <NormalButton id="Save" listener={()=>{}} color='white' bgColor='var(--darkPurple)' label={m.acept} fill={true}/>
              </a>
            </div>
          </div>
        </div>
      </div>
      }
    </div>
  )
}