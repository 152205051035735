import React, { useContext, useState } from 'react'
import { styled } from 'styled-components'
import RoundFlag from '../../shared/RoundFlag'
import PlaceholderImage from '../../shared/PlaceholderImage'
import { GreyButton } from './GreyButton'
import Modal from '../../shared/Modal'
import LoadingBars from './LoadingBars'
import { LangContext, translations } from '../../Language'
import api from '../../api'
import LoginField from '../form/LoginField'
import { GreenButton } from './GreenButton'

const InfoOrganizationContact = ({ org, view = false, curId, curOrgId, currentUserOrg, opId }) => {

  const g = translations[useContext(LangContext)].general
  const [modal, setModal] = useState(false)
  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(false);

  const configuration = {
    message_empty: "message_empty",
    error_message: "error_message",
    success_message: "success_message",
  };

  const callError = (msg) => {
    alert(msg);
    setLoading(false);
  };

  const validate = () => {
    if (message === "") {
      callError(g[configuration.message_empty]);
      return false;
    }
    return true;
  };

  const processResponse = (res) => {
    if (!res.success || res.success === false) {
      callError(g[configuration.error_message]);
    } else {
      callError(g[configuration.success_message],"info");
    }
  };

  const contactOp = () => {
    const information = {
      userId: curId,
      opId: opId,
      message: message,
    };
    api.contactOp(information, processResponse);
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
    /* Validations */
    if (loading) return;
    if (!validate(e)) return;
    /* After Validations */
    setLoading(true);
    /* API CALLS */
    contactOp();
  };
  
  return (
    <InfoOrgCont>
      <LogoCont>
        <PlaceholderImage image={org.image_url?.includes("missing") ? null : org.image_url} />
      </LogoCont>
      <InfoCont>
        <InfoOrg>
          <h3>{org.name}</h3>
        </InfoOrg>
        <InfoOrg>
          <RoundFlag id={org.country} width={"9.1rem"} height={"9.1rem"}/>
          <h4>{g.country[org.country]}</h4>
        </InfoOrg>
      </InfoCont>
      {view && curId && currentUserOrg && curId !== curOrgId &&
        <GreyButton $width={"24.9rem"} $height={"4.3rem"} onClick={()=>setModal(true)}>{g.contact}</GreyButton>
      }
      {modal &&
        <Modal title={g.contactTitle} initialOpen={true} close={()=>setModal(false)} width={"916px"} height={"491px"}>
          <ContactForm onSubmit={handleSubmit}>
            <LoginField type={"textarea"} name="msg" value={message} onChange={(e) => setMessage(e.target.value)} label={g.message}/>
            <ButtonCont>
              {loading ? <LoadingBars/> : <GreenButton $width={"20.3rem"} $height={"6.2rem"} type='submit'>{g.sendMessage}</GreenButton> }
            </ButtonCont>
          </ContactForm>
        </Modal>
      }
    </InfoOrgCont>
  )
}

const InfoOrgCont = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 5rem;
  background-color: #ECECEC;
  padding: 5rem 5%;
`
const LogoCont = styled.div`
  width: 20rem;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  overflow: hidden;
  background-color: white;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
`
const InfoCont = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  font-family: var(--BarlowCondensed);
  gap: 1rem;
`
const InfoOrg = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;

  h3{
    font-size: 5rem;
    font-weight: 600;
    margin: 0;
    color: var(--purple2);
    text-transform: uppercase;
  }
  
  h4{
    font-size: 4rem;
    font-weight: 600;
    margin: 0;
    color: var(--redWine);
    text-transform: uppercase;
  }
`
const ContactForm = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  
  div:first-child, textarea{
    flex: 1;
  }
  label{
    color: white;
  }
`
const ButtonCont = styled.div`
  width: 24.9rem;
  height: 8rem;
  align-self: flex-end;
  display: flex;
  align-items: center;
  justify-content: center;
`
export default InfoOrganizationContact