import styled from "styled-components";

export const GreyButton = styled.button`
  width: ${({$width}) => $width || "auto"};
  height: ${({$height}) => $height || "auto"};
  border: solid 1px #FFF;
  background-color: ${({$disable}) => $disable ? "#696863" : "var(--grey1)"};
  font-family: var(--Roboto);
  font-size: 2rem;
  font-weight: 500;
  color: ${({$disable}) => $disable ? "white" : "#383232"};
  white-space: nowrap;
  padding: ${({$paddingY}) => $paddingY || 0} ${({$paddingX}) => $paddingX || 0};
  cursor: ${({$disable}) => $disable ? "auto" : "pointer"};
  transition: all .5s;
  text-transform: ${({$textTransform}) => $textTransform || ""};
  border-radius: 6px;

  &:hover{
    color: white;
    background-color: #696863;
  }
  
  &:active{
    transform: scale(1.1);
  }

  @media (max-width: 440px) {
    width: 140px;
    height: fit-content;
    font-size: 2.5rem;
    padding: 1.5rem 1rem 1rem;
  }
`