import React, { useContext, useEffect, useState } from "react";
import { parseISO } from "date-fns";
import { LangContext, translations } from "../Language";
import Layout from "./Layout";
import ViewField, { Images } from "../Form/ViewField";
import useOpController from "../hooks/useOpController";
import FichaPropuesta from "../Proposals/FichaProposal";
import api from "../api";
import useFormUpdate from "../hooks/useFormUpdate";
import Modal from "../shared/Modal";
import ModalPost from "../layout/ModalPost";
import NewPanel from "../Form/NewPanel";
import InfoOrganizationContact from "../styled_components/shared/InfoOrganizationContact";
import { ViewContain } from "../styled_components/layout/ViewContain";
import { Label } from "../Form/FormField";
import styled from "styled-components";
import { GreyButton } from "../styled_components/shared/GreyButton";

const testEnvironment = process.env.REACT_APP_NODE_ENV === 'test'

export default ({ object, currentUser, currentUserOrg, selectors, getSelector, hiddenPanelButtons, dispatch, adminUser, removeBackEvent }) => {
  const [data, saveData, setData] = useFormUpdate("opportunity", object);
  const [score, setScore] = useState(false);
  const l = useContext(LangContext);
  const f = translations[l].forms.opportunities;
  const c = translations[l].forms.controls;
  const d = translations[l].definition;
  const common = { data, f, c, d, l, view: true, selectors, getSelector };
  const accordion = useOpController(common);

  const curId = currentUser && currentUser.id;
  const curOrgId = data.user_id;

  console.log('data', data)

  let props = {
    data: [
      {title: f.oppTypeFormTitle, component: <Definition {...{ d, l, data, currentUser, currentUserOrg, getSelector }}/>},
      {title: f.oppBasicFormTitle, component: <Contribution {...{ d, l, f, data, getSelector }} />},
      ...accordion,
			{title: f.oppAttachmentsFormTitle, component: <Attachments {...common}/>},
    ],
    c,
  };

  useEffect(() => {
    const showCardTimer = setTimeout(() => {
      const content = document.getElementById(`oppView`)
      content.scrollIntoView({ behavior: "smooth" })
      clearTimeout(showCardTimer)
    }, 300)
  }, [])

  return (
    <>
      <ViewContain id="oppView" $paddingY={"5%"} $paddingX={"10%"}>
        <InfoOrganizationContact {...{ curId, curOrgId, currentUserOrg }} org={data.organization} opId={data.id} view />
        <Layout {...props} view />
        {!hiddenPanelButtons && <NewPanel {...{ data, saveData, setScore, dispatch, setData, removeBackEvent }} mode="preview" model="opportunities" />}
        <div>
        {(currentUser?.id === data.user_id || adminUser )&& <Proposals {...{ data, l }} />}
        </div>
      </ViewContain>
      {score && (
        <Modal width={"916px"} height={"650px"} title={"Tu oportunidad ha sido publicada"} close={() => { window.location = `${testEnvironment?"/marketplace":""}/opportunities/${data.id}`; }} >
          <ModalPost score={score} listener={() => { window.location = `${testEnvironment?"/marketplace":""}/opportunities/${data.id}`; }} />
        </Modal>
      )}
    </>
  );
};

const BtnProposal = ({ data, g }) => {
  let handleClick = () => {
    window.location = `${testEnvironment?"/marketplace":""}/proposals/new?opportunity_id=${data.id}`;
  };
  return (
    <ViewContain $alignItems={"center"}>
      <GreyButton $paddingY={"1rem"} $paddingX={"1rem"} onClick={handleClick} $textTransform={"uppercase"}>{g.postProposal}</GreyButton>
    </ViewContain>
  );
};

const BtnCloseOpportunity = ({ data, g }) => {
  let handleClick = () => {
    api.closeOpportunity({ id: data.id, authenticity_token: window.at }, (response) => {
      response.success ? location.reload() : alert(response.message);
    });
  };
  return (
    <ViewContain $alignItems={"center"}>
      <GreyButton $width={"24.9rem"} $height={"4.3rem"} onClick={handleClick} $textTransform={"uppercase"}>{g.closeOpportunity}</GreyButton>
    </ViewContain>
  );
};

const Definition = ({ d, l, data, currentUser, currentUserOrg, getSelector }) => {
  const curId = currentUser && currentUser.id;
  const curOrgId = data.user_id;
  const g = translations[l].general;
  const c = translations[l].forms.controls;

  const ValidateDate = (date = "2023-03-17") => {
    // validate date for show button or field
    let today = new Date().getTime();
    let parseDate = parseISO(date).getTime();
    let validation = today < parseDate;
    return validation;
  };

  return (
    <>
      {ValidateDate(data?.deadline) && currentUser && currentUserOrg && curId === curOrgId && data?.published && <BtnCloseOpportunity {...{ data, g }} />}
      {currentUser && currentUserOrg && curId !== curOrgId && <BtnProposal {...{ data, g }} />}
      {data.idoneo && (
        <IdoneoCont>
          <img src="/icons/idoneidad.png" alt="idoneo" />
          <IdoneoDescription>{d.idoneo}</IdoneoDescription>
        </IdoneoCont>
      )}
      {data.partner_id && <ViewField object={data} f={d} t="partner" field="partner" />}
      <ViewField object={data} t="booleanSub" field="type" subField="offers" label={d.type} />
      <ViewField object={data} t="subContent" field="type" subField="name" label={d.definition} />
      <ViewField object={data} t="sub_sectors" field="sub_sectors" label={c.selectSectors} />
      {!ValidateDate(data?.deadline) && <ViewField object={data} t="text" field="deadline" label={d.dateClose} />}
      <ViewField object={data} t="selector" field="nb_affected" label={d.impact} getSelector={getSelector} />
      {data.tags.length !== 0 && <ViewField object={data} t="contentArr" field="tags" label={d.concept} />}
      <OdsForm>
        <Label small={true} help={true} label={g.sdg}>
          {translations[l].odsExplication.explication}
        </Label>
        <OdsContainer>
          {data.ods.map((e) => ( <img key={"ODS_" + e.id} src={e.image} alt={e.number} />
          ))}
        </OdsContainer>
      </OdsForm>
      <ViewField object={{proposals_count: data.proposals.length}} t="text" field="proposals_count" label={g.submittedproposals}/>
    </>
  );
};

const Contribution = ({ d, l, f, data, getSelector }) => {
  const i = translations[l].opportunity.impact;
  const props = { l, d, data };

  return (
    <>
      <ViewField {...props} t="text" field="title" label={f.viewTitle}/>
      <ViewField {...props} t="selector" field="participation" label={i.participation} getSelector={getSelector} />
      <ViewField object={data} f={d} t="text" field="description" />
      <ViewField object={data} f={d} t="text" field="motivation" />
      <ViewField object={data} f={d} t="country" field="country" />
      <ViewField {...props} t="text" field="other_participation" label={i.other_participation} getSelector={getSelector} />
    </>
  );
};

const Attachments = (props) => (
  <>
    <Images object={props.data} t="images" />
    <ViewContain $paddingY={"4%"} >
      <ViewField object={props.data} t="files" field="files" label={props.f.anexosView} />
    </ViewContain>
    <ViewField object={props.data} t="videos" field="urlVideos" label={props.c.urlVideosView} />
    <ViewContain $paddingY={"4%"} >
      <ViewField object={props.data} t="links" field="attached_links" label={props.f.attachedLinksView} />
    </ViewContain>
  </>
);

const Proposals = ({ data, l }) => (
  <div className="proposals">
    <h3>{translations[l].general.proposals}</h3>
    <div className="flex flex-wrap">
      {data.proposals.map((e) => (
        <div key={"propuesta" + e.id}>
          <a href={`${testEnvironment?"/marketplace":""}/proposals/${e.id}`}>
            <FichaPropuesta proposal={e} />
          </a>
        </div>
      ))}
    </div>
  </div>
);

//Idoneo
const IdoneoCont = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`
const IdoneoDescription = styled.span`
  font-size: 2.5rem;
  font-weight: 400;
  color: var(--grey5);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

//ODS
const OdsForm = styled.div`
  display: flex;
  flex-direction: column;
`
const OdsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  img{
    width: 80px;
  }
`